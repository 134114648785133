<template>
  <div id="technology" class="technology">
    <b-container>
      <b-row class="text-center">
        <b-col>
          <h2>The Technology</h2>
          <p>LYNK looks at the most effective pieces of the modern payment landscape and bundles them all into one easy to use platform.<br><br>For the operators, there’s a whole suite of communications, marketing, survey and loyalty tools available. Keep your customers engaged and active. Get feedback directly from the source.</p>
        </b-col>
        </b-row>
        <b-row>
          <b-card class="technology-card" img-src="../assets/tech-icon-1@2x.png" img-alt="Card image" img-top>
            <b-card-text>
              <h5>Cross-Platform Support</h5>
              <ul class="feature-list">
                <li>Android</li>
                <li>Web browser</li>
                <li>USSD (keypad phone)</li>
              </ul>
            </b-card-text>
          </b-card>
          <b-card class="technology-card" img-src="../assets/tech-icon-2@2x.png" img-alt="Card image" img-top>
            <b-card-text>
              <h5>Cutting-Edge Security</h5>
              <ul class="feature-list">
                <li>End-to-End rotating encryption</li>
                <li>Biometric verification</li>
                <li>Multi-factor authentication</li>
                <li>Artificial-Intelligence-based cybersecurity</li>
              </ul>
            </b-card-text>
          </b-card>
          <b-card class="technology-card" img-src="../assets/tech-icon-3@2x.png" img-alt="Card image" img-top>
            <b-card-text>
              <h5>Maximized Accessibility</h5>
              <ul class="feature-list">
                <li>Android</li>
                <li>Web browser</li>
                <li>USSD (keypad phone)</li>
              </ul>
            </b-card-text>
          </b-card>
        </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Technology',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "node_modules/bootstrap/scss/functions";
  @import "node_modules/bootstrap/scss/variables";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";

  .technology {
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    background-image: url('../assets/technology-bg.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    &-card {
      flex: 1 0 33%;
      background-color: rgba(0,0,0,0);
      border: none;
      @include media-breakpoint-down(sm) {
        flex: 0 0 50%;
      }
      @include media-breakpoint-down(xs) {
        flex: 0 0 50%;
      }
      &:last-child {
        @include media-breakpoint-down(sm) {
          margin: 0 auto;
        }
      }
    }
    .container {
      padding: 6rem 0;
    }
  }

  .feature-list {
    list-style: none;
    padding-left: 0px;
  }

  .card-img-top {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  // .tech-intro {
  //   margin: 0 16rem 2rem 16rem;
  // }

  h2 {
    margin-bottom: 1.5rem;
  }
</style>
