<template>
  <div>
    <b-navbar class="nav-pad" toggleable="md" type="dark" :variant="variant">
      <b-navbar-brand href="/">
        <b-img src="@/assets/logo-nav@2x.png" class="logo-nav"></b-img>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <li class="nav-item active">
              <router-link to="/#intro" class="nav-link" v-smooth-scroll>
                About
              </router-link>
            </li>
            <li class="nav-item active">
              <router-link to="/#platform" class="nav-link" v-smooth-scroll>
                Platform
              </router-link>
            </li>
            <li class="nav-item active">
              <router-link to="/#technology" class="nav-link" v-smooth-scroll>
                Technology
              </router-link>
            </li>
            <!-- <li class="nav-item active">
              <router-link to="/#statistics" class="nav-link" v-smooth-scroll>
                Statistics
              </router-link>
            </li> -->
            <li class="nav-item active">
              <router-link to="/#contact" class="nav-link" v-smooth-scroll>
                Contact
              </router-link>
            </li>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    variant: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .nav-pad {
    padding: .5rem 2rem;
  }

  .logo-nav {
    width: 2em !important;
  }
</style>
