<template>
  <div id="home">
    <Header />
    <Intro />
    <FeatureList />
    <Technology />
    <ContactForm />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Intro from '../components/Intro.vue'
import FeatureList from '../components/FeatureList.vue'
import Technology from '../components/Technology.vue'
import ContactForm from '../components/ContactForm.vue'

export default {
  name: 'home',
  components: {
    Header,
    Intro,
    FeatureList,
    Technology,
    ContactForm
  }
}
</script>

<style lang="scss" scoped>

</style>
