<template>
  <div>
    <Navbar variant="secondary" />
    <b-container class="py-4">
      <b-row>
        <b-col class="content text-center">
          <div class="block">
            <img src="/static/ban.svg" alt="">
          </div>
          <div class="block">
            <h1>Oops, looks like something went wrong!</h1>
          </div>
          <div class="block">
            <router-link to="/">
              <button class="btn btn-primary">Back Home</button>
            </router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Navbar from './Navbar.vue'

  export default {
    name: "SubmissionFail",
    components: {
      Navbar
    }
  }
</script>

<style lang="scss" scoped>
  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .block {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
</style>
