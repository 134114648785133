import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/axios'
import './plugins/bootstrap-vue'
import vueSmoothScroll from 'vue2-smooth-scroll'
import VueMq from 'vue-mq'
import App from './App.vue'
import router from './router'

Vue.use(VueMq, {
  breakpoints: {
    xs: 450,
    sm: 900,
    md: 1250,
    lg: Infinity
  }
});

Vue.use(vueSmoothScroll)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount('#app')
