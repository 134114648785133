<template>
  <div>
    <b-jumbotron
      class="text-left hero-bg"
      bg-variant="transparent"
      text-variant="light"
    >
      <div class="hero-block">
        <b-container>
          <b-row>
            <b-col class="hero-content align-self-center" sm="12" md="6" offset-md="1">
              <h1 class="header">Smarter Payment Technology</h1>
              <h3 class="lead pb-3">Using AI and the blockchain to create a secure, efficient, and unified financial ecosystem for your business.</h3>
              <b-button class="hero-button" variant="primary" href="#contact" v-smooth-scroll>Learn More</b-button>
            </b-col>
            <b-col cols="3" offset-lg="1">
              <b-img src="@/assets/heroPhone.png" class="hero-phone" fluid-grow alt="Fluid-grow image"></b-img>
            </b-col>
          </b-row>

        </b-container>
      </div>
      <!-- <p>For more information visit website</p> -->
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.hero {
  &-button {
    padding: .5rem 4rem;
  }

  &-phone {
    min-width: 150px;
    max-width: 200px;
    transform: scale(1.2);
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &-block {
    display: flex;
    flex-direction: column;
  }
  &-content {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}
</style>
