<template>
  <div id="contact" class="contact py-4">
    <b-container>
      <b-row>
        <b-col>
          <div class="text-center py-4">
            <h2>
              Get In Contact With Us
            </h2>
          </div>
        </b-col>
      </b-row>
      <b-form @submit.prevent="handleSubmit" v-if="show" name="contact-form" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact-form" />
        <b-row class="py-2">
          <b-col>
            <b-form-group id="input-group-1">
              <b-form-input
              id="input-1"
              v-model="form.name"
              name="name"
              type="text"
              required
              placeholder="Your Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="py-2">
          <b-col>
            <b-form-group
            id="input-group-2"
            >
              <b-form-input
              id="input-2"
              v-model="form.email"
              name="email"
              type="email"
              required
              placeholder="Your Email"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="py-2">
          <b-col>
            <b-form-group id="input-group-3">
              <b-form-textarea
              id="input-3"
              v-model="form.message"
              name="message"
              type="text"
              required
              placeholder="Your Message"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pb-4">
          <b-col class="text-center">
            <b-button class="contact-submit" type="submit" variant="primary">Submit</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-container>

  </div>
</template>

<script>
  import axios from "axios";

  export default {
    name: 'ContactForm',
    props: {},
    data() {
      return {
        form: {
          email: '',
          name: '',
          message: ''
        },
        show: true
      }
    },
    methods: {
      encode (data) {
        return Object.keys(data)
          .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
          )
          .join("&");
      },
      handleSubmit () {
        const axiosConfig = {
          header: { "Content-Type": "application/x-www-form-urlencoded" }
        };
        axios.post(
          "/",
          this.encode({
            "form-name": "contact-form",
            ...this.form
          }),
          axiosConfig
        )
        .then(() => {
          this.$router.push('thanks')
        })
        .catch(() => {
          this.$router.push('404')
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .contact {
    background-color: #f8f8f8;
    &-submit {
      padding: .5rem 4rem;
    }
  }
</style>
