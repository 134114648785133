<template>
  <div class="footer">
    <b-container fluid>
      <b-row>
        <b-col>
          <b-navbar type="dark" variant="secondary">
            <b-navbar-brand href="#">
              <b-img src="@/assets/logo-nav@2x.png" class="footer-logo"></b-img>
            </b-navbar-brand>
            <b-navbar-nav class="ml-auto" type="dark">
              <router-link to="/privacy-policy" class="nav-link active" v-smooth-scroll>
                Privacy Policy
              </router-link>
              <router-link to="/terms-and-conditions" class="nav-link active" v-smooth-scroll>
                Terms & Conditions
              </router-link>
            </b-navbar-nav>
        </b-navbar>
      </b-col>
    </b-row>
    <b-row class="footer-post">
      <b-col>
        <p class="footer-copyright mx-3">©Copyright Lynk 2019. All Rights Reserved.</p>
      </b-col>
    </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.footer {
  margin-top: auto;
  background: #455a64;
  &-logo {
    width: 2em !important;
  }
  &-post {
    background-color: #787F91 !important;
    color: white
  }
  &-copyright {
    font-size: 12px;
    text-transform: uppercase;
    margin: .5rem 0;
  }
  .nav-link {
    @include media-breakpoint-down(sm) {
      font-size: 0.7rem;
    }
  }
}

// .navbar {
//   padding: 0.5rem 8rem !important;
// }
</style>
