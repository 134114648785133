import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import SubmissionSuccess from './components/SubmissionSuccess'
import SubmissionFail from './components/SubmissionFail'
import Terms from './components/Terms'
import Privacy from './components/Privacy'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/thanks',
      name: 'success',
      component: SubmissionSuccess
    },
    {
      path: '/404',
      name: 'fail',
      component: SubmissionFail
    },
    {
      path: '/terms-and-conditions',
      name: 'terms',
      component: Terms
    },
    {
      path: '/privacy-policy',
      name: 'privacy',
      component: Privacy
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
