<template>
  <div class="header-bg">
    <Navbar />
    <Hero />
  </div>
</template>

<script>
import Navbar from './Navbar.vue'
import Hero from './Hero.vue'

export default {
  name: 'Header',
  props: {},
  components: {
    Navbar,
    Hero
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-bg {
  background-image: url('../assets/hero-bg.jpg');
  background-size: cover;
  background-position: bottom;
  border-radius: 0;
}
</style>
