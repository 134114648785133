<template>
  <div id="app">
    <main id="main">
      <router-view/>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Footer from './components/Footer.vue'

export default {
  name: 'app',
  components: {
    Footer
  }
}
</script>

<style lang="scss">
@import './assets/styles/fonts.scss';
@import './assets/styles/custom.scss';

#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#main {
  flex-shrink: 0;
}
</style>
