<template>
  <div id="intro" class="py-3 mb-3">
    <b-row class="text-center">
      <b-col></b-col>
      <b-col sm="12" md="10" lg="8">
        Lynk is a multi-platform, multi-level financial ecosystem built on decentralized blockchain technology, which utilizes microservice architecture to ensure limitless scalability.
      </b-col>
      <b-col></b-col>
    </b-row>
    <b-row>
      <b-col></b-col>
      <b-col sm="10" md="8" lg="6">
        <b-img src="@/assets/intro-illustration@2x.png" fluid-grow alt="Fluid-grow image"></b-img>
      </b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
