<template>
  <div id="platform" class="feature">
    <div v-for="feature in features" v-bind:key="feature.id">
      <div class="feature-section py-4" :class="feature.bgColor">
        <b-container>
          <b-row align-v="center" :class="feature.order">
            <b-col cols="6" class="feature-block text-center">
              <b-img class="feature-image" :src="feature.imageUrl" slot="aside" alt="placeholder" />
            </b-col>
            <b-col cols="5" offset-md="1" offset-lg="1" class="feature-block">
              <h2 class="feature-title">{{ feature.title }}</h2>
              <p class="feature-body">
                {{ feature.body }}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureList',
  props: {
    title: String,
    body: String,
    order: String,
    bgColor: String,
    imageUrl: String
  },
  data () {
    return {
      features: [
        {
          id: '1',
          title: 'Fund Transfer',
          body: 'Transfer money and make payments to others; regardless of whether they are on the LYNK system, if they have a bank account or not, or what type of phone they use.',
          order: 'reverse',
          bgColor: 'gray',
          imageUrl: '/static/01_fund_transfer.png'
        },
        {
          id: '2',
          title: 'Merchant Systems',
          body: 'Register as a merchant on our platform and get access to inventory management, accounting and payment services instantly.',
          bgColor: 'white',
          imageUrl: '/static/02_merchant.png'
        },
        {
          id: '3',
          title: 'Loyalty Programs',
          body: 'Spend money through our platform. Earn points, Get rewards!',
          order: 'reverse',
          bgColor: 'gray',
          imageUrl: '/static/03_loyalty_programs.png'
        },
        {
          id: '4',
          title: 'TopUp',
          body: 'Recharge your phone balance or any other pre-paid services straight from your LYNK balance.',
          bgColor: 'white',
          imageUrl: '/static/04_topup.png'
        },
        {
          id: '5',
          title: 'Bill Payment',
          body: 'Pay your bills at the supermarket, the parking lot, your kid’s school, or even your electricity bill.',
          order: 'reverse',
          bgColor: 'gray',
          imageUrl: '/static/05_bill_payment.png'
        },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.white {
  background-color: #fff;
}
.gray {
  background-color: #f8f8f8;
}
.reverse {
  flex-direction: row-reverse;
}
.feature {
  &-title, &-body {
    text-align: left;
  }
  &-image {
    max-width: 190px;
    @include media-breakpoint-down(xs) {
      max-width: 120px;
    }
  }
}
</style>
